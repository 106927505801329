/*
 * Servizio che si occupa della gestione di un Progetto
*/
module ProjectService {
    let app = angular.module("app");

    app.factory("ProjectService", ["$resource", "GlobalApplicationData", ($resource: ng.resource.IResourceService, GlobalApplicationData: any) => {
        let serv = {
            getDetails: $resource(GlobalApplicationData.applicationContext + 'rest-api/library-mediator/get-item-by-id/:itemId', {
                itemId: "@itemId"
            }, {
                    query: {
                        method: 'GET',
                        isArray: false
                    }
                }, {
                    cancellable: true
                }),

            isMandatoryCourseCompletionValidForNextRoleAssignment: $resource(GlobalApplicationData.applicationContext + 'rest-api/library-mediator/is-mandatory-course-completion-valid-for-next-role-assignment', {
            }, {
                    query: {
                        method: 'GET',
                        isArray: false
                    }
                }, {
                    cancellable: true
                }),

            // Verifica lo stato di prestabilità di un Item
            hasReferenceOpenRents: $resource(GlobalApplicationData.applicationContext + 'rest-api/library-mediator/has-reference-open-rents', {
            }, {
                    query: {
                        method: 'GET',
                        isArray: false
                    }
                }, {
                    cancellable: true
                }),

            // Recupera il dettaglio del lang pool
            getLangPoolDetail: $resource(GlobalApplicationData.applicationContext + 'rest-api/library-mediator/get-lang-pool-detail', {}, {
                query: {
                    method: 'GET',
                    isArray: false
                }
            }, {
                    cancellable: true
                }),

            // Recupera l'url con il file contente il report delle sessioni           
            getSessionReportUrl: $resource(GlobalApplicationData.applicationContext + 'rest-api/library-mediator/users-item-and-scorm-registration-sessions', {
                itemId: "@itemId",
                langCode: "@langCode"
            }, {
                    query: {
                        method: 'GET',
                        isArray: false
                    }
                }, {
                    cancellable: true
                }),

            updatePlayerStatus: $resource(GlobalApplicationData.applicationContext + 'rest-api/library-mediator/update-player-status/:itemId/:event/:totalTime/:currentTime', {
                itemId: "@itemId",
                event: "@event",
                totalTime: "@totalTime",
                currentTime: "@currentTime"
            }, {
                    update: {
                        method: 'POST'
                    }
                }),

            // Duplica il Progetto
            duplicateProject: $resource(GlobalApplicationData.applicationContext + 'rest-api/library-mediator/create-project', {
            }, {
                    query: {
                        method: 'POST',
                        isArray: false
                    }
                }, {
                    cancellable: true
                }),

            doesTheItemExist: $resource(GlobalApplicationData.applicationContext + 'rest-api/library-mediator/does-the-item-exist/:itemId', {
                itemId: "@itemId"
            }, {
                    query: {
                        method: 'GET',
                        isArray: false
                    }
                }, {
                    cancellable: true
                }),

            // Recupera l'url con il file contente il report di completamento di un file
            getItemReportUrl: $resource(GlobalApplicationData.applicationContext + 'rest-api/library-mediator/users-status-for-item/:itemId', {
                itemId: "@itemId"
            }, {
                    query: {
                        method: 'GET',
                        isArray: false
                    }
                }, {
                    cancellable: true
                }),

            // Recupera l'url con il file contente il report per la formazione obbligatoria
            getItemReportForMandatoryTrainingUrl: $resource(GlobalApplicationData.applicationContext + 'rest-api/library-mediator/users-status-for-mandatory_training/:itemId', {
                itemId: "@itemId"
            }, {
                query: {
                     method: 'GET',
                     isArray: false
                }
            }, {
                cancellable: true
            }),

            // Controllo se è collegato almeno ad un oggetto del cm2 tramite external_object_id
            haveAtLeastOneCourseManagerItem: $resource(GlobalApplicationData.applicationContext + 'rest-api/library-mediator/list-existing-attribute-values/10', {
                attributeType: "@attributeType",
                attributeValue: "@attributeValue",
                originApplicationName: "@originApplicationName"                
            }, {
                    query: {
                        method: 'GET',
                        isArray: false
                    }
                }, {
                    cancellable: true
                }),

            // Recupera l'url con il file contente il report di completamento di un file
            getItemReportInPoolUrl: $resource(GlobalApplicationData.applicationContext + 'rest-api/library-mediator/users-status-for-item-in-pool/:itemId', {
                itemId: "@itemId"
            }, {
                    query: {
                        method: 'GET',
                        isArray: false
                    }
                }, {
                    cancellable: true
                }),

            // Aggiunge un engagement
            createEngagement: $resource(GlobalApplicationData.applicationContext + 'rest-api/library-mediator/create-engagement', {
            }, {
                    query: {
                        method: 'POST',
                        isArray: false
                    }
                }, {
                    cancellable: true
                }),

            deleteProject: $resource(GlobalApplicationData.applicationContext + 'rest-api/library-mediator/delete-project/:itemId', {
                itemId: "@itemId"
            }, {
                    query: {
                        method: 'POST',
                        isArray: false
                    }
                }, {
                    cancellable: true
                }),

            // Calcola le competenze e il monte ore
            getTagsAndTimeByItemContainer: $resource(GlobalApplicationData.applicationContext + 'rest-api/library-mediator/get-tags-and-time-by-item-container/:itemId', {
                itemId: "@itemId"
            }, {
                    query: {
                        method: 'GET',
                        isArray: false
                    }
                }, {
                    cancellable: true
                })
        };
        return serv;
    }]);
}